<template>
  <div class="workList">
    <PullRefresh
      :disabled="isRefreshDisable"
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :error="error"
      :isNoData="isNoData"
      className="my-refresh"
    >
      <PostItem v-for="item in list" :key="item.id" :postInfo="item" />
    </PullRefresh>
  </div>
</template>

<script>
import PullRefresh from "@/components/PullRefresh";
import PostItem from "@/components/PostItem";
import { queryWorkList, queryLikeList } from "@/api/mine";
export default {
  components: {
    PostItem,
    PullRefresh,
  },
  props: {
    isRefreshDisable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    type: {
      type: String,
      default() {
        return "work";
      },
    },
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        uid: this.$route.query.uid ? this.$route.query.uid : undefined,
        // uid: 224411,
        type: "new",
        // newsType: "COVER"
      };
      try {
        let res = await this.$Api(
          this.type == "work" ? queryWorkList : queryLikeList,
          req
        );
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          list.forEach((i) => {
            i.isFollow = true;
          });
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    // 关注-取消关注
    // async careOrcancle(item, index) {
    //     let req = {
    //         followUID: parseInt(item.uid),
    //         isFollow: !item.isFollow,
    //     }
    //     if (!item.uid) {
    //         Toast('当前用户信息错误');
    //         return;
    //     }
    //     this.$store.commit('app/SET_LOADING', true);
    //     try {
    //         let res = await this.$Api(careOrcancle, req);
    //         this.$store.commit('app/SET_LOADING', false);
    //         if (res.code === 200) {
    //             Toast(req.isFollow ? "关注成功" : "取消成功")
    //             item.isFollow = req.isFollow;

    //             return;
    //         }
    //         Toast(res.tip || `${req.isFollow ? '关注失败' : '取消失败'}`);
    //     } catch (error) {
    //         this.$store.commit('app/SET_LOADING', true);
    //         Toast('操作失败')
    //     }

    // },
  },
};
</script>

<style lang="scss" scoped>
.workList {
  // height: calc(100vh - 416px);

  .list {
    padding: 0 12px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 9px;
    grid-column-gap: 9px;

    .workItem {
      width: 111px;
      height: 162px;
      position: relative;

      .mask {
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        height: 24px;
        background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.9));

        .timer {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0 8px;
          box-sizing: border-box;
          font-size: 13px;
        }
      }
    }
  }
}
</style>
