<template>
  <div class="userHomepage">
    <div class="optBox oepnX" :class="{ isFixed: isFixed }">
      <div @click="$router.go(-1)">
        <img class="backIcon" src="@/assets/png/leftH.png" />
        <!--                <svg-icon class="backIcon" :iconClass="isFixed ? 'navArrow' : 'backIcon'" />-->
      </div>
      <div class="uBox" v-show="isFixed">
        <ImgDecypt
          class="avatar"
          :src="userInfo.portrait"
          :key="userInfo.portrait"
          round
        />
        <div>{{ userInfo.name }}</div>
      </div>
      <div
        class="follow"
        v-show="isFixed"
        :class="{ followed: userInfo.isFollow }"
        v-if="userInfo.uid != currentUser.uid"
        @click="careOrcancle"
      >
        <svg-icon v-if="!userInfo.isFollow" icon-class="btnAdd"></svg-icon>
        {{ userInfo.isFollow ? "已关注" : "关注" }}
      </div>
      <div
        v-show="isFixed"
        @click="$router.push('editBackground')"
        class="follow selectBg"
        v-else
      >
        <div>更换背景</div>
      </div>
      <!--            <router-link tag="div" to="myMsg" class="rightBox" v-if="userInfo.uid == currentUser.uid">-->
      <!--                <svg-icon class="privateLetter" iconClass="privateLetter" />-->
      <!--                &lt;!&ndash; <svg-icon class="setting" iconClass="setting" /> &ndash;&gt;-->
      <!--            </router-link>-->
    </div>
    <div class="contentBox">
      <div class="hearBox">
        <ImgDecypt class="bgImg" :src="backgroundImg" :key="backgroundImg" />
        <div
          class="follow"
          :class="{ followed: userInfo.isFollow }"
          v-if="userInfo.uid != currentUser.uid"
          @click="careOrcancle"
        >
          <svg-icon v-if="!userInfo.isFollow" icon-class="btnAdd"></svg-icon>
          {{ userInfo.isFollow ? "已关注" : "关注" }}
        </div>
        <div
          @click="$router.push('editBackground')"
          class="follow selectBg"
          v-else
        >
          <div>更换背景</div>
        </div>
        <div class="backgroundMask"></div>
        <!-- <div class="homePagebtn"></div> -->
        <div class="avatarBox">
          <div class="avatarBorder">
            <ImgDecypt
              class="avatarImg"
              :src="userInfo.portrait"
              :key="userInfo.portrait"
              round
            />
          </div>
          <!-- <div @click="toEditInfo">
                          <svg-icon class="editIcon" iconClass="editIcon" />
                      </div> -->
          <div class="nameAndLabel">
            <div class="userName">{{ userInfo.name }}</div>
            <svg-icon
              class="vipIcon"
              iconClass="vipIcon"
              v-if="userInfo.isVip"
            />
            <svg-icon
              class="userIcon"
              iconClass="mercIcon"
              v-if="checkTime(userInfo.officialCertExpire)"
            />
            <svg-icon
              class="userIcon"
              iconClass="bloggerIcon"
              v-if="checkTime(userInfo.superUserExpire)"
            />
            <svg-icon
              class="userIcon"
              iconClass="supremeIcon"
              v-if="checkTime(userInfo.videoFreeExpiration)"
            />
          </div>
        </div>
      </div>
      <div class="main">
        <!--                <div class="nameAndLabel">-->
        <!--                    <div class="userName">{{userInfo.name}}</div>-->
        <!--                    <svg-icon class="vipIcon" iconClass="vipIcon" v-if="userInfo.isVip" />-->
        <!--                    <svg-icon class="userIcon" iconClass="supremeIcon" v-if="checkTime(userInfo.videoFreeExpiration)" />-->
        <!--                    <svg-icon class="userIcon" iconClass="mercIcon" v-if="checkTime(userInfo.officialCertExpire)" />-->
        <!--                    <svg-icon class="userIcon" iconClass="bloggerIcon" v-if="checkTime(userInfo.superUserExpire)" />-->
        <!--                </div>-->
        <div class="userInfo">
          <div class="userId">涩漫ID：{{ userInfo.uid }}</div>
          <div class="smallVerticalLine"></div>
          <div>{{ userInfo.age || 0 }}岁</div>
          <div class="smallVerticalLine" v-if="userInfo.region"></div>
          <div class="city">{{ userInfo.region }}</div>
          <div class="smallVerticalLine" v-if="userInfo.gender"></div>
          <svg-icon
            class="sexIcon"
            iconClass="maleIcon"
            v-if="userInfo.gender == 'male'"
          />
          <svg-icon
            class="sexIcon"
            iconClass="womanIcon"
            v-if="userInfo.gender == 'female'"
          />
        </div>
        <div class="summary">
          {{ userInfo.summary || "这人很懒，还没设置自己的个性签名" }}
        </div>

        <div class="userData">
          <router-link
            tag="div"
            :to="`/focusList?uid=${userInfo.uid}`"
            class="dataItem"
          >
            <div class="dataText">关注</div>
            <div class="dataNum">{{ userInfo.follow | watchCount }}</div>
          </router-link>
          <div class="verticalLine"></div>
          <router-link
            tag="div"
            :to="`/fanList?uid=${userInfo.uid}`"
            class="dataItem"
          >
            <div class="dataText">粉丝</div>
            <div class="dataNum">{{ userInfo.fans | watchCount }}</div>
          </router-link>
          <div class="verticalLine"></div>
          <div class="dataItem">
            <div class="dataText">点赞</div>
            <div class="dataNum">{{ userInfo.like | watchCount }}</div>
          </div>
        </div>

        <!--                <div class="introduction">{{userInfo.summary || '才不是懒得写签名呢，只是这个人很神秘！'}}</div>-->
        <van-tabs
          v-model="activeTab"
          sticky
          animated
          background="#FFF"
          color="#FBD13D"
          title-active-color="#333333"
          title-inactive-color="#666666"
          offset-top="1.173rem"
          class="postTab"
          @scroll="scroll"
        >
          <van-tab title="作品">
            <PostList :isRefreshDisable="isRefreshDisable" type="work" />
          </van-tab>
          <van-tab title="喜欢">
            <PostList :isRefreshDisable="isRefreshDisable" type="like" />
          </van-tab>
          <!--                    <van-tab title="帖子">-->
          <!--                        <PostList :isRefreshDisable="isRefreshDisable" />-->
          <!--                    </van-tab>-->
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { queryUserInfo } from "@/api/user";
import { PullRefresh, Toast, Tab, Tabs } from "vant";
import { careOrcancle } from "@/api/user";
import ImgDecypt from "@/components/ImgDecypt";
// import WorkList from "./WorkList";
import PostList from "./PostList";
export default {
  name: "UserHomePage",
  components: {
    ImgDecypt,
    // WorkList,
    PostList,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      currentUser: {}, // 当前用户
      userInfo: {}, // 用户信息
      backgroundImg: "", // 背景
      walletInfo: {}, // 钱包信息
      couponNum: 0, // 观阅券数量
      isLoading: false,
      activeTab: 0,
      isFixed: false,
      scrollTop: 0,
      isRefreshDisable: false,
    };
  },
  created() {
    this.currentUser = JSON.parse(sessionStorage.getItem("userInfo"));
    this.queryUserInfo();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
  },
  methods: {
    scrollToTop() {
      let dom = document.querySelector(".contentBox");
      this.scrollTop = dom.scrollTop;
      // console.log(this.scrollTop)
      if (this.scrollTop > 0) {
        this.isRefreshDisable = true;
      } else {
        this.isRefreshDisable = false;
      }
    },
    // 刷新
    onRefresh() {
      this.queryUserInfo();
    },
    // 查询用户信息
    async queryUserInfo() {
      let req = this.$route.query.uid
        ? {
            uid:
              this.$route.query.uid != this.$store.getters.userInfo.uid
                ? this.$route.query.uid
                : 0,
          }
        : {
            uid: 0,
          };
      let res = await this.$Api(queryUserInfo, req);
      this.isLoading = false;
      if (res.code === 200) {
        this.userInfo = res.data;
        this.backgroundImg = this.userInfo.background
          ? this.userInfo.background[0]
          : "";
      }
    },
    // 校验有效时间
    checkTime(timer) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(timer).getTime();
      if (timer && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
    scroll(scrollTop) {
      this.isFixed = scrollTop.isFixed;
    },
    // 关注-取消关注
    async careOrcancle() {
      // console.log(this.userInfo.uid)
      let req = {
        followUID: this.userInfo.uid,
        isFollow: !this.userInfo.isFollow,
      };
      if (!this.userInfo.uid) {
        Toast("当前用户信息错误");
        return;
      }
      let res = await this.$Api(careOrcancle, req);
      if (res.code === 200) {
        Toast(req.isFollow ? "关注成功" : "取消成功");
        this.userInfo.isFollow = req.isFollow;
        return;
      }
      Toast(res.tip || `${req.isFollow ? "关注失败" : "取消关注失败"}`);
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
};
</script>

<style lang="scss" scoped>
.userHomepage {
  height: 100%;
  color: #000;
  //background: #F1F1F1;

  .contentBox {
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .optBox {
    width: 100%;
    position: fixed;
    // top: 12px;
    padding: 0 12px;
    z-index: 10;
    box-sizing: border-box;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .backIcon {
      width: 24px;
      height: 24px;
    }

    .uBox {
      flex: 2;
      display: flex;
      align-items: center;
      margin-left: 10px;

      .avatar {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }

    .rightBox {
      display: flex;
      align-items: center;

      .privateLetter {
        width: 20px;
        height: 17px;
        // margin-right: 12px;
      }

      .setting {
        width: 18px;
        height: 21px;
      }
    }
  }

  .isFixed {
    background: #fff;
    .follow {
      width: 60px;
      height: 24px;
      border-radius: 25px;
      line-height: 25px;
      color: #424242;
      background: #fbd13d;
      text-align: center;
      font-size: 12px;
    }
    .followed {
      color: #ccc;
      font-size: 12px;
      background: #fafafa;
    }
    .selectBg {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .hearBox {
    width: 100%;
    height: 150px;
    position: relative;

    .backgroundMask {
      height: 150px;
      width: 100%;
      background-image: linear-gradient(
        180deg,
        #ffffff00 0%,
        #130f3270 50%,
        #130f32 100%
      );
      position: absolute;
      bottom: 0;
      z-index: 1;
    }

    .follow {
      font-size: 12px;
      width: 60px;
      height: 24px;
      border-radius: 25px;
      line-height: 25px;
      color: #424242;
      background: #fbd13d;
      text-align: center;
      position: absolute;
      bottom: -12px;
      right: 16px;
      z-index: 3;
    }

    .followed {
      font-size: 12px;
      background: #fafafa;
    }

    .selectBg {
      font-size: 12px;
      font-weight: 500;
    }

    // .homePagebtn {
    //     position: absolute;
    //     right: 12px;
    //     z-index: 2;
    //     bottom: 0;
    //     width: 65px;
    //     height: 33px;
    //     background: url("../../assets/png/homePageBtn.png") no-repeat;
    //     background-size: 100% 100%;
    // }

    .avatarBox {
      position: absolute;
      bottom: -31px;
      left: 16px;
      z-index: 3;
      display: flex;
      align-items: flex-end;

      .avatarBorder {
        width: 60px;
        height: 60px;
        // padding: 5px;
        border-radius: 50%;
        box-sizing: border-box;
        background: #1d164c;
        border: solid #fbd13d 1.88px;

        .avatarImg {
          width: 100%;
          height: 100%;
        }
      }
     .nameAndLabel {
      padding-right: 12px;
      box-sizing: border-box;
      // margin-top: 24px;
      display: flex;
      align-items: center;

      .userName {
       font-size: 14px;
       font-weight: bold;
       color: #333333;
       margin-right: 12px;
      }

      .vipIcon {
       width: 28px;
       height: 15px;
       margin-right: 4px;
      }

      .userIcon {
       width: 42px;
       height: 15px;
       margin-right: 4px;
      }
     }

      .userName {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin-left: 12px;
      }

      .editIcon {
        width: 24.3px;
        height: 24.3px;
      }
    }
  }

  .main {
    // overflow: hidden;
    height: calc(100% - 180px);
    //padding-top: 35px;
    box-sizing: border-box;

    .smallVerticalLine {
      width: 1px;
      height: 10px;
      margin: 0 10px;
      background: #333333;
    }

    .nameAndLabel {
      padding: 0 12px;
      box-sizing: border-box;
      // margin-top: 24px;
      display: flex;
      align-items: center;

      .userName {
        font-size: 18px;
        margin-right: 12px;
      }

     .vipIcon {
      width: 28px;
      height: 15px;
      margin-right: 4px;
     }

     .userIcon {
      width: 42px;
      height: 15px;
      margin-right: 4px;
     }
    }

    .userInfo {
      display: flex;
      align-items: center;
      padding: 40px 16px 12px 16px;
      box-sizing: border-box;
      font-size: 12px;
      //margin: 12px 0;
      color: #999999;
      background: #ffffff;

      .sexIcon {
        width: 12px;
        height: 12px;
        margin-right: 3px;
        font-size: 200px;
      }
    }
    .summary {
      padding: 0 16px;
      //margin-bottom: 12px;
      font-size: 12px;
      color: #666666;
      background: #ffffff;
    }

    .userData {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      padding: 12px 16px;
      box-sizing: border-box;
      background: #ffffff;

      .verticalLine {
        width: 1px;
        height: 20px;
        //margin: 0 12px;
        background: #333333;
      }

      .dataItem {
        display: flex;
        align-items: center;
        //min-width: 43px;

        .dataNum {
          font-size: 16px;
          color: #333333;
        }

        .dataText {
          font-size: 12px;
          margin-right: 8px;
          color: #333333;
        }
      }
    }

    .introduction {
      font-size: 15px;
      color: #827f9d;
      margin-top: 12px;
      padding: 0 12px;
      box-sizing: border-box;
    }

    .postTab {
      //margin-top: 11px;
      // height: calc(100% - 214px);
      //background: #F1F1F1;

      /deep/ .van-sticky--fixed {
        top: 44px;
      }

      /deep/ .van-tabs__wrap {
        padding: 0 12px 12px;
        box-sizing: border-box;
        background: #ffffff;
        height: 59px;
        // width: 180px;
        // height: 36px;
      }

      /deep/ .van-tabs__content {
        height: 100%;
      }

      /deep/ .van-tab__pane {
        height: 100%;
      }

      /deep/ .van-tabs__line {
        width: 18px;
        height: 2px;
        bottom: 20px;
      }

      ///deep/ .van-tab__text {
      //    font-size: 18px;
      //}

      /deep/ .van-tabs__nav {
        display: flex;
        // padding:  0 12px;
        // box-sizing: border-box;
        // display: grid;
        // grid-template-columns: 1fr 1fr;
      }

      /deep/ .van-tab {
        width: 50px;
        padding: 0;
        margin-right: 36px;
        flex: none;
        font-size: 12px !important;
        // justify-content: flex-start;
      }

      /deep/ .van-tab--active {
        font-size: 14px !important;
      }
    }

    .mb80 {
      margin-bottom: 150px;
    }
  }
}
</style>
